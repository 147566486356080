#page-name-container {
  position: absolute;
  background: white;
  border-radius: 0 0 16px 16px;
  width: 350px;
  padding: 0;
  text-align: center;
  height: 52.4px;
  left: 225px;
  box-shadow: 2px 1px 9px -2px rgba(0,0,0,0.2);
}

#strategy-manager-back-btn-container {
  position: absolute;
  background: white;
  border-radius: 0 0 16px 0;
  width: 65px; height: 50.4px;
  box-shadow: 0px -0px 9px -2px rgba(0,0,0,0.1);
  border: 0;
  align-items: center;
  display: flex;
}