#strategy-name-container {
  position: absolute;
  background: white;
  border-radius: 16px;
  width: 500px;
  padding: 0;
  height: 52.4px;
  left: 70px; top: 30px;
  box-shadow: 0px -0px 9px -2px rgba(0,0,0,0.1);
}

.strategy-editor-header {
  font-family: helvetica;
  color: #343434;
}

#edit-btn-container {
  background: transparent;
  border: 0;
  display: flex;
  cursor: pointer;
}
#edit-btn-container > div > input {
  margin-left: 1rem;
  border: 0;
  font-family: helvetica;
  max-height: 52.4px;
}

.edit-btn {
  position: relative;
  background: url('../../images/pencil.svg');
  background-size: 23px;
  height: 23px !important; width: 23px !important;
  max-height: 23px !important; max-width: 23px !important;
  border: 0;
}

.edit-container {
  display: flex;
  max-height: 48px;    
  margin-left: .5rem!important;
}

.edit-container > input {
  padding: 0;
  border: 0;
  background-color: transparent;
}

.save-container {
  color: darkgreen;
  background-color: transparent;
  border: 0;
  padding: 0;
  display: flex;
}
.save-container::before {
  margin: 3px;
  content: "\2713";
}

.cancel-container {
  color: crimson;
  background-color: transparent;
  padding: 0;
  margin-right: 5px;
  border: 0;
  display: flex;
}
.cancel-container::before {
  margin: 3px;
  content: "\2715";
}

#edit-btn-icon{
  height: 23px; width: 23px;
  fill:#9e9e9e;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-left: 5px;
  top: -50%;
  -ms-transform: translateY(60%);
  transform: translateY(60%);
}
#strategy-name-container:hover > #edit-btn-container > #edit-btn-icon{
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  fill:#686868;
}

#save-btn-container {
  position: absolute;
  background: white;
  border-radius: 16px;
  width: 65px; height: 50.4px;
  left: 585px; top: 30px;
  box-shadow: 0px -0px 9px -2px rgba(0,0,0,0.1);
  border: 0;
}
#save-btn-icon{
  height: 35px; width: 35px;
  fill:#949494;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  display: block;
  margin-right: auto;
  margin-left: auto;
}
#save-btn-container:hover > #save-btn-icon{
  cursor: pointer;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  fill:#82af89;
}
#save-btn-container:disabled{
  cursor: default;
}
#save-btn-container:disabled > #save-btn-icon{
  cursor: default;
  fill:rgba(148, 148, 148, 0.5) !important;
}

#back-btn-container {
  position: absolute;
  background: white;
  border-radius: 16px;
  width: 65px; height: 50.4px;
  left: 665px; top: 30px;
  box-shadow: 0px -0px 9px -2px rgba(0,0,0,0.1);
  border: 0;
  align-items: center;
  display: flex;
}
#back-btn-icon{
  height: 35px; width: 35px;
  fill:#949494;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  display: block;
  margin-right: auto;
  margin-left: auto;
}
#back-btn-container:hover > #back-btn-icon{
  cursor: pointer;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  fill:#b85656;
}