#at-container {
    position: absolute;
    background: white;
    border-radius: 16px;
    width: 211px; height: 263px;
    left: 260px; top: 178px;
    box-shadow: 0px -0px 9px -2px rgba(0,0,0,0.1);
}

#at-header {
    position: relative;
    left: 13px; top: 5px;
    font-size: 14px;
    font-family: Helvetica;
    color: #9F9F9F;
}

#ot-list-container {
    position: absolute;
    width: 191px;  height: 230px;
    left: 10px; top: 25px;
    overflow-x: hidden;
    overflow-y: auto;
}
#ot-list-container::-webkit-scrollbar {
    display: none;
}

#ot-list-row {
    position: relative;
    width: 100%;  height: 40px;
    left: 0px; top: 0px;
    border-radius: 4px;
    margin-bottom: 2px;
}
#ot-list-row:hover {
    background-color: #f0f0f0;
}
  
#at-ticker {
    position: relative;
    left: 6px;  top: -5px;
    font-size: 23px;
    font-family: Helvetica;
    color: #343434;
    z-index: 0;
}

.trade-qty-icon {
    position: relative;
    top: -1px;
    width: 10px;
}

.trade-qty {
    position: relative;
    width: 100px;  height: 30px;
    left: 5px; top: -30px;
    font-size: 14px;
    font-family: Helvetica;
  }

#at-current-price {
    position: absolute;
    width: 70px;
    left: 95px; top: 0px;
    font-size: 16px;
    font-family: Helvetica;
    text-align: right;
}

#at-profit-loss-pct {
    position: absolute;
    width: 70px;
    left: 95px; top: 20px;
    font-size: 14px;
    font-family: Helvetica;
    text-align: right;
}

#at-figure {
    position: absolute;
    width: 12px;  height: 30px;
    left: 175px; top: 5px;
}