.strategy-info-container {
  width: 290px; height: 195px;
  margin: 6px 0;
  padding-bottom: 0;
  overflow-y: auto;
}
/* width */
.strategy-info-container::-webkit-scrollbar {
  width: 6px;
}
/* Track */
.strategy-info-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
} 
/* Handle */
.strategy-info-container::-webkit-scrollbar-thumb {
  background: rgb(216, 216, 216); 
}
/* Handle on hover */
.strategy-info-container::-webkit-scrollbar-thumb:hover {
  background: rgb(179, 179, 179); 
}

.restart-backtest-btn {
  font-size: 14px;
  height: auto;
  padding: .175rem .75rem;
  background-color: #9c9c9c; 
  border-color: #9c9c9c;
}
.restart-backtest-btn:hover {
  background-color: #838383; 
  border-color: #838383;
}
.restart-backtest-btn:active {
  background-color: #838383 !important; 
  border-color: #838383 !important;
}
.restart-backtest-btn:focus {
  background-color: rgb(131, 131, 131) !important; 
  border-color: #838383 !important;
  box-shadow: 0 0 0 0.2rem rgb(131 131 131 / 50%);
}
.restart-backtest-btn:disabled {
  background-color: #bbc1cc; 
  border-color: #bbc1cc;
}

.edit-backtest-btn {
  font-size: 14px;
  height: auto;
  padding: .175rem .75rem;
  background-color: #f19e66; 
  border-color: #f19e66;
}
.edit-backtest-btn:hover {
  background-color: #e2915b; 
  border-color: #e2915b;
}
.edit-backtest-btn:active {
  background-color: #e2915b !important; 
  border-color: #e2915b !important;
}
.edit-backtest-btn:focus {
  background-color: #e2915b !important; 
  border-color: #e2915b !important;
  box-shadow: 0 0 0 0.2rem rgb(226 145 91 / 50%);
}
.edit-backtest-btn:disabled {
  background-color: #6c757d; 
  border-color: #6c757d;
}

.delete-backtest-btn {
  font-size: 14px;
  height: auto;
  padding: .175rem .75rem;
  background-color: #f16666; 
  border-color: #f16666;
}
.delete-backtest-btn:hover {
  background-color: #e94f4f; 
  border-color: #e94f4f;
}
.delete-backtest-btn:active {
  background-color: #e94f4f !important; 
  border-color: #e94f4f !important;
}
.delete-backtest-btn:focus {
  background-color: rgb(233, 79, 79) !important; 
  border-color: #e94f4f !important;
  box-shadow: 0 0 0 0.2rem rgb(233 79 79 / 50%);
}
.delete-backtest-btn:disabled {
  background-color: #f07d89; 
  border-color: #f07d89;
}

.chevron__line1, .chevron__line2 {
  stroke-width: 10;
  stroke-linecap: round;
  -webkit-transition: -webkit-transform 0.4s, stroke 0.4s;
  transition: transform 0.4s, stroke 0.4s;
}
.chevron__line1 {
  -webkit-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
}
.chevron__line2 {
  -webkit-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
}
.chevron--down, .chevron--up {
  display: block;
  width: 20px;
  height: 20px;
}
.chevron--down .chevron__container, .chevron--up .chevron__container {
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  -webkit-transform: translateY(13px);
  transform: translateY(13px);
}
.chevron--down .chevron__line1, .chevron--up .chevron__line1 {
  stroke: #949494;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}
.chevron--down .chevron__line2, .chevron--up .chevron__line2 {
  stroke: #949494;
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
}
.chevron--up .chevron__container {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}
.chevron--up .chevron__line1 {
  stroke: #949494;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.chevron--up .chevron__line2 {
  stroke: #949494;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

#arrow-icon{
  height: 14px; width: 14px;
  fill:#949494;
  transition: 0.30s;
  -webkit-transition: 0.30s;
  -moz-transition: 0.30s;
  -ms-transition: 0.30s;
  -o-transition: 0.30s;
}
#arrow-icon.flip {
  transition: 0.30s;
  -webkit-transition: 0.30s;
  -moz-transition: 0.30s;
  -ms-transition: 0.30s;
  -o-transition: 0.30s;
  transform: rotate(-90deg);
}

#backtest-container {
  padding: 3px 0;
  margin-bottom: 4px;
}

#backtest-separator {
  margin: 5px;
}

#backtest-stat-text {
  font-family: Helvetica;
  font-size: 9pt;
  color: #343434;
}