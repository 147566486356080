#ot-stats-container {
    position: absolute;
    background: white;
    border-radius: 16px;
    width: 211px;  height: 70px;
    left: 260px; top: 96px;
    box-shadow: 0px -0px 9px -2px rgba(0,0,0,0.1);
}

#ot-stats-qty {
    position: relative;
    left: 10px; top: -7px;
    font-size: 51px;
    font-family: Helvetica;
    color: #343434;
}

#ot-stats-text {
    position: absolute;
    left: 43px; top: 8px;
    font-size: 15px;
    font-family: Helvetica;
    color: #343434;
}
  
#ot-stats-value {
    position: absolute;
    width: 108px;
    left: 95px; top: 20px;
    font-size: 36px;
    font-family: Helvetica;
    color: #343434;
    text-align: center;
}