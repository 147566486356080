#ct-container {
  position: absolute;
  background: white;
  border-radius: 16px;
  width: 272px; height: 345px;
  left: 483px; top: 96px;
  box-shadow: 0px -0px 9px -2px rgba(0,0,0,0.1);
}

#ct-header {
  position: relative;
  left: 13px; top: 5px;
  font-size: 14px;
  font-family: Helvetica;
  color: #a3a3a3;
}

#ct-list-container {
  position: absolute;
  width: 252px;  height: 310px;
  left: 10px; top: 25px;  
  overflow-x: hidden;
  overflow-y: auto;
}
#ct-list-container::-webkit-scrollbar {
  display: none;
}

#ct-list-row {
  position: relative;
  width: 100%;  height: 44px;
  left: 0px; top: 0px;
  border-radius: 4px;
  margin-bottom: 2px;
}
#ct-list-row:hover {
  background-color: #f0f0f0;
}

#ct-ticker {
  position: relative;
  left: 6px;  top: -5px;
  font-size: 24px;
  font-family: Helvetica;
  color: #343434;
  z-index: 0;
}

#ct-profit-loss-pct {
  position: absolute;
  width: 75px;
  left: 95px; top: -1px;
  font-size: 18px;
  font-family: Helvetica;
  text-align: right;
}

#ct-profit-loss-value {
  position: absolute;
  width: 75px;
  left: 90px; top: 22px;
  font-size: 14px;
  font-family: Helvetica;
  text-align: right;
}

#ct-figure {
  position: absolute;
  width: 75px;  height: 36px;
  left: 173px; top: 4px;
}