#selections-container {
  position: absolute;
  background: white;
  border-radius: 16px;
  width: 320px; height: 330px;
  left: 70px; top: 100px;
  box-shadow: 0px -0px 9px -2px rgba(0,0,0,0.1);
}

#selection-row-container {
  position: absolute;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  width: 290px; height: 260px;
  left: 15px; top:50px;
  overflow-y: auto;
}
/* width */
#selection-row-container::-webkit-scrollbar {
  width: 6px;
}

/* Track */
#selection-row-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
#selection-row-container::-webkit-scrollbar-thumb {
  background: rgb(192, 192, 192); 
}

/* Handle on hover */
#selection-row-container::-webkit-scrollbar-thumb:hover {
  background: rgb(141, 141, 141); 
}

#selection-row {
  background-color: white;
  border: 2px solid rgb(233, 233, 233);
  border-radius: 16px;
  width: 290px; height: 60px;
  margin-bottom: 3px;
}


#selection-row.invalid {
  background-color: rgb(255, 154, 154);
}
#selection-row.invalid.selected {
  background-color: rgb(230, 128, 128);
}

#selection-row.invalid > div > #remove-btn-container > #remove-btn-icon{
  height: 65%; width: 65%;
  fill:#be4848;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  margin-right: auto;
  margin-left: auto;
}
#selection-row.invalid > div > #remove-btn-container:hover > #remove-btn-icon{
  cursor: pointer;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  fill:#913030;
}

#selection-row.selected {
  background-color: rgb(235, 235, 235);
}

.add-module-btn {
  background-color: white !important;
  text-align: left;
}
.add-module-btn:focus {
  box-shadow: 0 0 0 .2rem rgba(163, 163, 163, 0.329) !important;
}

hr.header-rule {
  border: 0;
  height: 1px;
  margin: 10px 0;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.397), rgba(0, 0, 0, 0.397), rgba(0, 0, 0, 0));
}

#analysis-module-box {
  height: 120px;
  text-align: center;
  border: 2px solid black;
  border-radius: 16px;
  cursor: pointer;
}

#analysis-module-box > h5 {
  max-width: 80%;
}


#analysis-module-box.disabled {
  border-color: rgb(216, 216, 216);
  cursor: default;
}

#analysis-module-box.disabled > h5 {
  color: rgb(175, 175, 175);
}

#remove-btn-container {
  background: transparent;
  border: 0;
  padding: 0;
  top: -50%;
  -ms-transform: translateY(60%);
  transform: translateY(60%);
}
#remove-btn-icon{
  height: 65%; width: 65%;
  fill:#9c9c9c;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  margin-right: auto;
  margin-left: auto;
}
#remove-btn-container:hover > #remove-btn-icon{
  cursor: pointer;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  fill:#b85656;
}