#selection-config-container {
  position: absolute;
  background: white;
  border-radius: 16px;
  width: 320px; height: 330px;
  left: 410px; top: 100px;
  box-shadow: 0px -0px 9px -2px rgba(0,0,0,0.1);
}

#selection-config-editor-container {
  position: absolute;
  width: 290px; height: 260px;
  left: 15px; top:50px;
  overflow-y: auto;
}
/* width */
#selection-config-editor-container::-webkit-scrollbar {
  width: 6px;
}

/* Track */
#selection-config-editor-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
#selection-config-editor-container::-webkit-scrollbar-thumb {
  background: rgb(192, 192, 192); 
}

/* Handle on hover */
#selection-config-editor-container::-webkit-scrollbar-thumb:hover {
  background: rgb(141, 141, 141); 
}