#new-btn-container {
  position: relative;
  background: rgb(248, 248, 248);
  border-radius: 8px;
  left: 10px;
  font-size: 14px;
  width: 85px; height: 25.4px;
  border: 0;
  align-items: center;
  display: flex;
  text-decoration: none !important;
  color: #343434;
  padding: 0 10px;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  font-family: Helvetica;
}
#new-btn-icon{
  height: 10px; width: 10px;
  fill:#949494;
  display: block;
  margin-left: auto;
  transform: rotate(45deg);
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
}
#new-btn-container:hover > #new-btn-icon{
  cursor: pointer;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  fill:#707070;
}
#new-btn-container:hover{
  cursor: pointer;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  background-color:#e9e9e9;
}

#last-run-icon{
  height: 16px; width: 16px;
  fill:#949494;
}

#avg-success-icon{
  height: 14px; width: 14px;
  fill:#949494;
}

.saved-strategy-name-container h5 {
  width: 256px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.saved-strategy-name {
  font-family: helvetica;
  color: #343434;
  font-size: 13pt;
}