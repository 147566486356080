#trade-stats-header {
    position: absolute;
    left: 247px; top: 47px;
    font-size: 28px;
    font-family: Helvetica;
    color: #343434;
}

#stats-container {
  position: absolute;
  width: 190px;
  height: 95px;
  /* background-color: rgb(230,230,230); */
  left: 245px;
  top: 90px;
}

#stat-label {
  margin: -3px 2px 2px 2px;
  font-size: 11px;
}