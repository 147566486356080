#settings-container {
  position: absolute;
  background: white;
  border-bottom-left-radius: 16px;
  width: 65px; height: 55px;
  left: 735px;
  box-shadow: -4px 4px 10px -2px rgba(0,0,0,0.13);
}

#settings-btn {
  padding: 0;
  border: 0;
  background-color: transparent;
}
#settings-btn:focus {
  outline: none;
  box-shadow: none;
}
#settings-btn:hover {
  cursor: default;
}

#settings-icon {
  height: 40px; width: 40px;
  fill:#646464;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

#settings-icon:hover {
  cursor: pointer;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.rotated {
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.modal-90w {
  width: 400px;
  margin: 0 !important
}

.modal {
  position: absolute;
  width: 800px;
  height: 480px;
  display: flex !important;
  
  justify-content: center;
  align-items: center;
}

.modal-body {
  padding-top: 0;
}

.modal-open {
  overflow: auto !important;
}

.modal * {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}

.modal-backdrop {
  width: 800px;
  height: 480px;
}

.settings-form-label {
  font-size: 14px;
}

.settings-form-input {
  font-size: 14px;
  height: auto;
  padding: .175rem .75rem;
}

.settings-form-select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
}

.settings-form-submit-btn {
  font-size: 14px;
  height: auto;
  padding: .175rem .75rem;
  background-color: #8cc2c4; 
  border-color: #8cc2c4;
}
.settings-form-submit-btn:hover {
  background-color: #72adaf; 
  border-color: #72adaf;
}
.settings-form-submit-btn:active {
  background-color: #72adaf !important; 
  border-color: #72adaf !important;
}
.settings-form-submit-btn:focus {
  background-color: #72adaf !important; 
  border-color: #72adaf !important;
  box-shadow: 0 0 0 0.2rem rgb(114 173 175 / 50%);
}
.settings-form-submit-btn:disabled {
  background-color: #6c757d; 
  border-color: #6c757d;
}